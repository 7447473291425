<template>
    <div>
                <Pane />

    <a-card class="container">

<div class="card-header">
        <div class="card-title">项目信息</div>
</div>

          <a-descriptions bordered :column="2" size='small'>
      <a-descriptions-item >
        <div slot="label" class="center">设计编号</div>
        <div>
          {{ detail.designCode }}
        </div>
      </a-descriptions-item>
            <a-descriptions-item >
        <div slot="label" class="center">合同名称</div>
        <div>
          {{ detail.name }}
        </div>
      </a-descriptions-item>
            <a-descriptions-item >
        <div slot="label" class="center">类别</div>
        <div>
                  <span v-if="detail.type === 'selfOper'">自营类</span>
              <span v-if="detail.type === 'admin'">管理类</span>
        </div>
      </a-descriptions-item>
            <a-descriptions-item >
        <div slot="label" class="center">合同金额(元)</div>
        <div style="color: #1890ff">
          {{ detail.contractAmount ? detail.contractAmount.toLocaleString() : '--' }}
        </div>
      </a-descriptions-item>
    </a-descriptions>


<div class="card-header">
        <div class="card-title">投标利润</div>
        <a-button type='primary' @click="addBidList">新增</a-button>
</div>
      <a-table
        bordered
        :data-source="[...bidList, bidAll]"
        :loading="loading"
        :pagination="false"
      >
            <a-table-column title="序号" width="50px" align="center">
                <template slot-scope="text, row, index">
                    {{ index === bidList.length  ? '合计' : text.num }}
                </template>
            </a-table-column>

        <a-table-column title="类别" align='center'>
          <template slot-scope="text, row, index">
              <span v-if="index === bidList.length ">
              </span>
               <a-input v-else v-model="text.cate" />
          </template>
        </a-table-column>


        <a-table-column title="拟投标价(元)"  >
          <template slot-scope="text, row, index">
              <span v-if="index === bidList.length ">
                {{text.preBidPrice}}
              </span>
              <span v-else>
                <span v-if="isCategory(text, index, bidList)" style="color: #1890ff">
                    {{ getCategoryMoney(text, 'preBidPrice', index, bidList).toLocaleString() }}
                </span>
                <a-input-number v-else v-model="text.preBidPrice"/>
              </span>
               
          </template>
        </a-table-column>
                <a-table-column title="承担主体" >
         <template slot-scope="text, row, index">
               <span v-if="index === bidList.length ">
              </span>
            <a-input v-else v-model="text.respSubject" />
          </template>
        </a-table-column>


        <a-table-column title="投标控制预算(元)"  >
          <template slot-scope="text, row, index">
              <span v-if="index === bidList.length ">
                {{text.bidBudget}}
              </span>
              <span v-else>
                <span v-if="isCategory(text, index, bidList)" style="color: #1890ff">
                    {{ getCategoryMoney(text, 'bidBudget', index, bidList).toLocaleString() }}
                </span>
                <a-input-number v-else v-model="text.bidBudget"/>
              </span>
          </template>
        </a-table-column>
                <a-table-column title="投标利润(元)"  >
          <template slot-scope="text, row, index">
                   <span v-if="index === bidList.length ">
                {{text.bidProfit}}
              </span>
              <span v-else>
                <span v-if="isCategory(text, index, bidList)" style="color: #1890ff">
                    {{ getCategoryMoney(text, 'bidProfit', index, bidList).toLocaleString() }}
                </span>
                <a-input-number v-else v-model="text.bidProfit"/>
              </span>
          </template>
        </a-table-column>
        
        <a-table-column title="备注">
         <template slot-scope="text, row, index">
                       <span v-if="index === bidList.length ">
                {{text.remark}}
              </span>
            <a-input v-else v-model="text.remark" />
          </template>
        </a-table-column>
        <a-table-column title="操作" width='120px' align='right'>
           <template slot-scope="text, row, index">
                         <span v-if="index === bidList.length ">
              </span>
            <span v-else>
                <a-space v-if="noDot(text)">
                  <a href="javascript:void(0)" @click="addSonA(text, index)">新增</a>
                  <a href="javascript:void(0)"  v-if="canIDeleteA(text)" class="danger" @click="removeA(index)">删除</a>
                </a-space>
                <a href="javascript:void(0)" v-else class="danger" @click="removeA(index)">删除</a>
            </span>
          </template>
        </a-table-column>
      </a-table>

        <div class="card-header">
        <div class="card-title">实施控制预算利润</div>

        <a-button type='primary' @click="add">新增</a-button>
</div>

            <a-table
        bordered
          :data-source="[...bidBudgetList, budgetAll]"
        :loading="loading"
        :pagination="false"
        rowKey="num"
      >

            <a-table-column title="序号" width="50px" align="center">
                <template slot-scope="text, row, index">
                    {{ index === bidBudgetList.length  ? '合计' : text.num }}
                </template>
            </a-table-column>

        <a-table-column title="类别" align='center'>
            <template slot-scope="text, row, index">
                          <span v-if="index === bidBudgetList.length ">
              </span>
               <a-input v-else v-model="text.cate" />
          </template>
        </a-table-column>

                        <a-table-column title="合同价(元)"  >
          <template slot-scope="text, row, index">
                   <span v-if="index === bidBudgetList.length ">
                {{text.contractPrice}}
              </span>
              <span v-else>
                <span v-if="isCategory(text, index, bidBudgetList)" style="color: #1890ff">
                    {{ getCategoryMoney(text, 'contractPrice', index, bidBudgetList).toLocaleString() }}
                </span>
                <a-input-number v-else v-model="text.contractPrice"/>
              </span>
          </template>
        </a-table-column>

                        <a-table-column title="承担主体" >
         <template slot-scope="text, row, index">
               <span v-if="index === bidBudgetList.length ">
              </span>
            <a-input v-else v-model="text.respSubject" />
          </template>
        </a-table-column>

                        <a-table-column title="实施控制预算(元)"  >
          <template slot-scope="text, row, index">
                   <span v-if="index === bidBudgetList.length ">
                {{text.impBidBudget}}
              </span>
              <span v-else>
                <span v-if="isCategory(text, index, bidBudgetList)" style="color: #1890ff">
                    {{ getCategoryMoney(text, 'impBidBudget', index, bidBudgetList).toLocaleString() }}
                </span>
                <a-input-number v-else v-model="text.impBidBudget"/>
              </span>
          </template>
        </a-table-column>

                        <a-table-column title="目标利润(元)"  >
          <template slot-scope="text, row, index">
                   <span v-if="index === bidBudgetList.length ">
                {{text.targetProfit}}
              </span>
              <span v-else>
                <span v-if="isCategory(text, index, bidBudgetList)" style="color: #1890ff">
                    {{ getCategoryMoney(text, 'targetProfit', index, bidBudgetList).toLocaleString() }}
                </span>
                <a-input-number v-else v-model="text.targetProfit"/>
              </span>
          </template>
        </a-table-column>

                        <a-table-column title="实际利润(元)"  >
          <template slot-scope="text, row, index">
                   <span v-if="index === bidBudgetList.length ">
                {{text.actualProfit}}
              </span>
              <span v-else>
                <span v-if="isCategory(text, index, bidBudgetList)" style="color: #1890ff">
                    {{ getCategoryMoney(text, 'actualProfit', index, bidBudgetList).toLocaleString() }}
                </span>
                <a-input-number v-else v-model="text.actualProfit"/>
              </span>
          </template>
        </a-table-column>

                <a-table-column title="备注">
         <template slot-scope="text, row, index">
                       <span v-if="index === bidBudgetList.length ">
              </span>
            <a-input v-else v-model="text.remark" />
          </template>
        </a-table-column>

                   <a-table-column title="操作" width='120px' align='right'>
           <template slot-scope="text, row, index">
                         <span v-if="index === bidBudgetList.length ">
              </span>
            <span v-else>
                <a-space v-if="noDot(text)">
                  <a href="javascript:void(0)" @click="addSon(text, index)">新增</a>
                  <a href="javascript:void(0)"  v-if="canIDelete(text)" class="danger" @click="remove(index)">删除</a>
                </a-space>
                <a href="javascript:void(0)" v-else class="danger" @click="remove(index)">删除</a>
            </span>
          </template>
        </a-table-column>
      </a-table>

      <div class="center" style="margin-top: 80px;padding-bottom: 80px">
        <a-space>
               <a-button @click="$close($route.path)">关闭</a-button>
              <a-button type="primary" :loading="saveLoading" @click="save">确认修改</a-button>
        </a-space>
      </div>
      
    </a-card>
    </div>
</template>



<script>

import request from '@/api/request'

function fetchDetail(params) {
    return request({
        url:  '/market-service/epc/view/profit/queryById',
        params,
    })
}

function update(data) {
    return request({
        url:  '/market-service/epc/view/profit/update',
        method: 'post',
        data,
    })
}

export default {

    name: 'epc_profit_edit',
    data() {
        return {
            loading: false,
            detail: {},
            bidList: [],
            bidBudgetList: [],

            saveLoading: false,
        }
    },

    computed: {
      bidAll() {
        let preBidPrice = 0;
        let bidBudget = 0;
        let bidProfit = 0;
        this.bidList.forEach(item => {
            if(item.num.indexOf('.') > 0) {
              return;
            }
            if (typeof item.preBidPrice === 'number') {
              preBidPrice += item.preBidPrice;
            }
               if (typeof item.bidBudget === 'number') {
              bidBudget += item.bidBudget;
            }
               if (typeof item.bidProfit === 'number') {
              bidProfit += item.bidProfit;
            }
        });
        return {
          num: '#',
          preBidPrice,
          bidBudget,
          bidProfit,
        }
      },
      budgetAll() {
            let contractPrice = 0;
        let impBidBudget = 0;
        let targetProfit = 0;
        let actualProfit = 0;

          this.bidBudgetList.forEach(item => {
            if(item.num.indexOf('.') > 0) {
              return;
            }
            if (typeof item.contractPrice === 'number') {
              contractPrice += item.contractPrice;
            }
               if (typeof item.impBidBudget === 'number') {
              impBidBudget += item.impBidBudget;
            }
               if (typeof item.targetProfit === 'number') {
              targetProfit += item.targetProfit;
            }
                  if (typeof item.actualProfit === 'number') {
              actualProfit += item.actualProfit;
            }
        });

        return {
          num: '#',
          contractPrice,
          impBidBudget,
          targetProfit,
          actualProfit
        }
      }
    },

    activated() {
        const { id } = this.$route.query;

        if (this.detail.id === id) return;
        this.loading = true;
        fetchDetail({
            id,
        }).then(res => {
          if (res) {
            this.detail = res;
            this.bidList = res.bidList || []
            this.bidBudgetList = res.bidBudgetList || []
          }
        }).finally(() => {
          this.loading = false;
        })
    },


    methods: {
      isCategory(text, index , list) {
        let hasChild = false;
        if(list && list[index + 1]){
          hasChild = list[index + 1].num.indexOf('.') > 0;
        }
        return text.num.indexOf('.') === -1 && hasChild;
      },
      getCategoryMoney(text, name, index, list) {
        const arr = list.filter(item => item.num.indexOf(text.num + '.') === 0)
        let amt = 0;
        arr.forEach(item => {
            if (typeof item[name] === 'number') {
                amt = amt + item[name]
            }
        })
        list[index][name]= amt;
        return amt;
      },
      addBidList() {
          const lastElement = this.bidList[this.bidList.length - 1] || { num: '0'}
          this.bidList.push({
            num: String( parseInt( lastElement.num) + 1)
          })
      },
      add() {
          const lastElement = this.bidBudgetList[this.bidBudgetList.length - 1] || { num: '0'}
          this.bidBudgetList.push({
            num: String( parseInt( lastElement.num) + 1)
          })
      },

        noDot(text) {
            return text.num.indexOf('.') === -1
        },


      addSonA(text, categoryIndex) {
          let index = -1;
          this.bidList.forEach((item, idx) => {
              if (item.num.indexOf(text.num + '.') === 0) {
                  index = idx;
              }
          })

          if (index === -1) {
                this.bidList.splice(categoryIndex + 1, 0, {
                  num: text.num + '.1'
              })
          }  else {
              const last = this.bidList[index]
              const newValue = parseInt(last.num.split('.')[1]) + 1;
              this.bidList.splice(index + 1, 0, {
                  num: text.num + '.' + newValue
              })
          }
      },
      addSon(text, categoryIndex) {
    
           let index = -1;
            this.bidBudgetList.forEach((item, idx) => {
                if (item.num.indexOf(text.num + '.') === 0) {
                    index = idx;
                }
            })

            if (index === -1) {
                  this.bidBudgetList.splice(categoryIndex + 1, 0, {
                    num: text.num + '.1'
                })
            }  else {
                const last = this.bidBudgetList[index]
                const newValue = parseInt(last.num.split('.')[1]) + 1;
                this.bidBudgetList.splice(index + 1, 0, {
                    num: text.num + '.' + newValue
                })
            }

 
      },
      canIDeleteA(text) {
            return this.bidList.filter(item => item.num.indexOf(text.num + '.') === 0).length === 0;
        },
          canIDelete(text) {
            return this.bidBudgetList.filter(item => item.num.indexOf(text.num + '.') === 0).length === 0;
        },
      removeA(index) {
        this.bidList.splice(index,1)
      },
      remove(index) {
          this.bidBudgetList.splice(index,1)
      },
      save() {
        this.saveLoading = true;

        console.log(this.bidList)

        update({
          ...this.detail,
          bidList: this.bidList,
          bidBudgetList: this.bidBudgetList,
        }).then(() => {
          this.$close(this.$route.path);
        }).finally(() => {
          this.saveLoading = false;
        })
      }
    }
}
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.card-header {
  margin-top: 16px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .card-title {
  font-weight: bold;
  font-size: 14px;
  color: #1890ff;
  }

  
}

</style>